<template>
    <section id="intro">
        <header>
            <h2>About</h2>
            <p>
                I'm a problem-solver by nature and by profession. I have a natural drive to find solutions to problems spoken and unspoken. By digging down through pain points, I excel in finding the heart of issues and coming up with the best solution within the constraints of the environment.
            </p>
            <p>
                While I've historically specialized in PHP, I always enjoy learning new things, and have recently been neck-deep in React and Electron while at a Ruby on Rails shop, and had a lot of fun dabbling in Rust and Tauri. I've also been eyeing software architecture as a potential role to grow into.
            </p>
        </header>
    </section>
</template>

<script>
export default {
}
</script>

<style>
</style>
